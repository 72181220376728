.required {
    color: #CF0000;
    padding-left: 5px; }

.field-invalid-msg {
    color: rgb(200, 50, 50); }

.title-field-org {
    color: silver !important; }

