@import "~styles/mixins";

.overset {
  color: #e74c3c;
  .meter {
    background-color: #e74c3c; } }

.underset {
  .meter {
    background-color: #f1c40f; } }

.valid {
  color: #2ecc71;
  .meter {
    background-color: #2ecc71; } }

.trans {
  color: transparent; }

.invalid {
  background-color: #e74c3c; }
