@import "~styles/mixins";
.nn-form-img-upload {
  ul {
    list-style: none;
    margin: 0;
    li {
      float: left;
      width: 100%;
      padding: 1rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #555; }
      img {
        width: 200px;
        max-height: 200px;
        border: 0;
        //float: left
        margin-right: 1rem; }
      .remove-file {
        opacity: 0.5;
        &:hover {
          opacity: 1; } }
      .progress {
        height: 3px;
        border: 0;
        padding: 0;
        margin-top: 5px; } }
    h5 {
      color: grey; } }
  .drag-area {
    border: 1px dashed #CCC;
    color: #CCC;
    //position: absolute
    height: 100px;
    width: 100%;
    .msg {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      @include font-size(15);
      opacity: 0.3;
      text-transform: uppercase; }
    &.drag-area-active {
      background: #555; } } }
