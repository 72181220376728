@import "~styles/mixins";

.nn-issue-list {
  select {
    line-height: 1.2em; }
  ul {
    list-style: none;
    @include nomargin;
    @include custom-list-dark;
    li {
      line-height: 19px;
      color: $link-panel-color;
      @include font-size(9);
      margin: 0.5em 0;
      padding: .5rem;
      border: 0px solid #777;
      cursor: pointer;
      overflow: hidden;
      width: auto;
      clear: none;
      .fa-chevron-down {
        opacity: 0.4; }
      &:focus, &:active, &.active {
        background: black; }
      .issue-date {
        &.active {
          .fa-chevron-down {
            @include icon-rotate(short); } } } } } }

