@import "~styles/mixins";

.nn-login-form {
  background: #fff;
  padding: 2rem;
  h1.brand {
    @include font-size(17);
    font-weight: 300;
    margin-bottom: 0.5em;
    color: $link-color; }
  input, input:focus {
    border-width: 0 0 1px 0;
    box-shadow: none;
    @include font-size(9);
    font-weight: 300; }
  input:focus {
    color: $brand-bg;
    background-color: transparent !important; }
  .login-button {
    background: transparent !important;
    clear: both;
    i {
      opacity: 0.8;
      color: $link-color; } }
  .login-remember {
    label, input {
      @include font-size(8); } }
  .error, .error-container {
    @include font-size(7); } }

