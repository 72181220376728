.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: #f0f3f4;
  opacity: 1;
  &.hidden {
    opacity: 0;
    transition: opacity .75s; }
  transition: opacity 0s;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%; } }

.spinner-container.hidden {
  .spinner {
    display: none; } }

.spinner-container:not(.hidden) {
  .spinner {
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    > div {
      width: 18px;
      height: 18px;
      background-color: #C8C8C8;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
      animation: bouncedelay 1.4s infinite ease-in-out;
      /* Prevent first frame from flickering when animation starts */
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      &.bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s; }
      &.bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s; } } } }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
