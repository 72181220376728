.nn-report-viewer {
    .padding-bottom {
        padding-bottom: 1em; }
    .border-bottom {
        border-bottom: 1px solid #e4e4e4; }
    .grid {
        border: 1px solid #e4e4e4; }
    .select {
        width: 200px; }
    .divider {
        line-height: 37px; }
    .reports {
        table {
            width: 100%;
            thead {
                white-space: nowrap;
                border-bottom: 1px solid #e4e4e4;
                th {
                    white-space: nowrap;
                    font-size: 0.8em;
                    padding: 5px 1em;
                    font-weight: normal;
                    background: #CEE2F4;
                    border-right: 1px solid #e4e4e4;
                    &:last-child {
                        border-right: none; }
                    &:first-child {
                        min-width: 175px; } } }
            td {
                border-right: 1px solid #e4e4e4;
                &:last-child {
                    border-right: none; } } } }
    .message {
        line-height: 1.5em; }
    .quickdate-button {
        margin: 0;
        height: 37px;
        line-height: 25px;
        font-size: 1em;
        background: #fafafa;
        color: #999 !important;
        padding-right: 3px;
        padding-left: 3px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
    .tooltip {
        line-height: 1.125rem; } }
