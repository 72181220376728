@import "mixins";
.nn-login {
  height: 100%;
  width: 100%;
  .nn-login-form {
    @include pos(absolute,0,0,0,auto);
    z-index: 2;
    section {
      position: absolute;
      left: 10%;
      top: 20%; } }
  video {
    z-index: 1;
    @include pos(absolute,0,0,0,0);
    height: 100%;
    background: #fff; } }
