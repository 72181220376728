@import "~styles/mixins";
.nn-file-upload {
  .alert-box {
    line-height: 1.5em;
    position: relative;
    white-space: nowrap;
    .total {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      font-size: 2em;
      opacity: 0.75; } }
  ul {
    list-style: none;
    margin: 0;
    li {
      float: left;
      width: 100%;
      padding: 1rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd; }
      .fa-check-circle {
        top: 0;
        right: 0; }
      .image {
        padding: 0.35em !important; }
      img {
        width: 100px;
        max-height: 100px;
        border: 0;
        float: left;
        margin-right: 1rem; }
      .remove-file {
        opacity: 0.5;
        &:hover {
          opacity: 1; } }
      .progress {
        height: 5px;
        border: 0;
        padding: 0;
        margin-top: 5px; } }
    h5 {
      color: white; }
    label {
      font-weight: bold; }
    .fields {
      @include font-size(6);
      line-height: 1em;
      margin-bottom: 0.5em; } }
  .drag-area {
    border: 1px dashed #CCC;
    color: #CCC;
    position: absolute;
    height: 77%;
    width: 370px;
    .msg {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      @include font-size(15);
      opacity: 0.3;
      text-transform: uppercase; }
    &.drag-area-active {
      background: #555; } } }
