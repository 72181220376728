@import "~styles/mixins";

.digital-check-list-description {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #f6f8f9; }

.question-mark {
  padding-right: 4px;
  padding-left: 3px;
  color: white;
  font-size: small; }

.update-score-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px; }

.description-text {
  font-size: smaller;
  margin-bottom: 0;
  text-align: center; }

.web-preview-container {
  height: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px; }

#story-layout-digital-iframe {
  width: 97%;
  border: 1px solid #e4e5e6; }

.preview-button-container {
  vertical-align: middle;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: 0.5px solid black; } }

.margin-right-8 {
  margin-right: 8px; }

.margin-left-4 {
  margin-left: 4px; }

#web-preview-not-enabled {
  cursor: pointer;
  pointer-events: all; }

@media only screen and (max-height: 900px) {
  .web-preview-container {
    height: 480px; } }


