.nn-story-editor {
  height: 100%;
  overflow: hidden;
  &:not(.editingImage) {
    padding-top: 45px; }
  iframe {
    border: 0;
    width: 100%;
    height: 100%; }
  .wider-panel, .wider-panel-withinfo {
    margin: 0 -84px 0 -100px; }
  .wider-panel {
    width: 120%; }
  .wider-panel-withinfo {
    width: 100%; } }

$black: rgba(48, 69, 92, 0.5);

.overlay {
	position: fixed;
	background: $black;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	z-index: 99999999 {
  display: none; } }

#overlayDialog {
	position: fixed;
	background: $black;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	z-index: 99999999 {
  display: none; } }

#story-layout {
  display: none;
  background-color: white;
  position: fixed;
  transition: width 0.2s, height 0.2s;
  -webkit-transition: width 0.2s, height 0.2s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 8px 0 rgba(0, 0, 0, 0.4); }

#layuout-tool-bar {
  width: 250px;
  height: 50px;
  background-color: black;
  z-index: 9999999;
  position: absolute;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  bottom: 100px;
  right: 100px;
  padding: 10px; }

#tool-bar-divider {
  border-right: 1px solid #9e9e9e;
  height: 30px; }

i:hover {
  color: white; }

