.nn-site-explorer {
    .site-explorer-idea {
        .fa {
            font-size: 1.2em; } }
    .site-explorer-runsheet {
        padding: 0.75em;
        color: #3B3E42;
        .runsheet-item {
            padding-left: 0.60em;
            @media only screen and (max-width: 400px) {
                margin-bottom: 1em; }
            .fa {
                margin-right: 0.25em; } } } }
