@import "~styles/mixins";

.nn-explorer {
  &.flatplan {
    width: calc(100% - 70px) !important;

    .explorer-panel-listbox {
      margin-bottom: 0px;
      padding-bottom: 15px; } }

  .explorer-top-bar i.active {
    background: #00aeef;
    color: white; } }

.nn-page-list {
  .page-list {
    display: block;
    width: 80%;
    margin: 0 auto;

    &.flat-plan {
      margin-top: 20px;
      width: 85%; }

    ul {
      display: inline-block;
      margin: 0px; }

    .page-element {
      overflow: hidden;

      &.story-hole {
        overflow: visible !important; } }

    li {
      cursor: pointer;
      margin-bottom: 10px;
      position: relative;
      display: inline-block;

      div.page-wrapper {
        border: 1px solid #ccc;
        display: inline-block;
        position: relative;
        z-index: 10;

        .templateNameTip {
          background: grey;
          display: none;
          padding: 2px 4px 0;
          color: white;
          width: 100%;
          position: absolute;
          text-align: center;
          top: 6px;
          font-size: 12px; }

        &:hover {
          border-color: #00aeef;

          .templateNameTip {
            display: block; } } }

      &:nth-child(odd):not(.left-page) {
        .page-wrapper {
          border-right: 1px solid rgba(238, 238, 238, 0.38); } }

      &.frontpage, &.backpage {
        margin-right: 20px;
        .page-wrapper {
          border-right: 1px solid #ccc !important; } } }


    &.layout-list {
      width: 80%;
      li.left-page {
        margin-right: 10px; } }

    ul.inside li:last-child div {
      border-left: 0px !important; }

    .page-meta {
      text-align: center;
      border-top: 1px solid #d6d6d6;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .fav {
        font-size: 1.0rem;
        position: relative;
        z-index: 20; }

      .flagged {
        position: absolute;
        left: 5px;
        padding: 5px;
        color: red;

        &.not {
          color: #cacaca;
          opacity: 0.7; }

        i.preferred-icon {
          color: #F99C22;
          display: inline-block;
          margin: 3px 3px 0 0; } }

      .right-box {
        position: absolute;
        right: 0;

        .fa-eye, .fa-check-circle, .fa-circle, .fa-exclamation {
          position: relative;
          padding: 5px;
          @include font-size(8);
          opacity: 0.5;

          &.active, &:hover {
            opacity: 1;
            color: $brand-bg; } }
        .fa-eye {
          right: 5px; } } }

    .page-element {
      position: absolute; }

    .add-template {
      position: absolute;
      top: 40%;
      left: 20%; } } }
