@import "~styles/mixins";

.nn-task-form {
  .select2-container {
    border: 1px solid #e2e2e2;
    box-shadow: none;
    .select2-chosen {
      span {
        @include font-size(7); } }
    .wrapper {
      line-height: 3em; } }
  .select2-result-label {
    border-bottom: 1px solut #cccccc;
    @include font-size(6);
    .profile-name {
      @include font-size(7); }
    .profile {
      @include pos(relative,0,auto,auto,0);
      float: left;
      margin-right: 10px; } }
  .select2-result-single {
    padding: 0;
    margin: 0; } }

.issue-page {
  @include font-size(7);
  line-height: 1.2em;
  @include pos(absolute,1.4em,auto,auto,0);
  width: 100%;
  z-index: 0;
  color: #666666;
  font-style: normal;
  padding: 0.5em 0.5em 1em 0.5em;
  span.invisible {
    padding-right: 1em; }
  display: none;
  .pubDetails {
    font-weight: 600; }
  &:hover {
    background: white; } }

.tasklist-assignee.ng-hide-add {
  display: none; }
