@import "~styles/mixins";

.nn-dashboard {

  h4 {
    font-weight: 200;
    line-height: 1.4em; }

  h5.username {
    line-height: 1.9em; }

  .switch {
    margin-top: -3px; }

  /* All mine task switch */
  label.tasks {
    line-height: 1.9em;
    margin-right: 1em; }
  .switch-ios {
    @include font-size(7);
    &.switch-toggle {
      a {
        background-color: #d8d8d8; }
      label, input, a {
        outline: none;
        &:focus {
          outline: none; } } } }

  ul.plain {
    margin-bottom: 1em;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.05);
    box-shadow: 0 1px 1px rgba(0,0,0,0.05);

    li.price {

      padding: 1em 0.65em 0.9em;
      background: white;
      border-bottom: 1px #eee solid;
      &.task-wrapper {
        padding: 0.55em 0.50em 0.55em 0; }

      .task-bar {
        .inline-list {
          margin-top: 0.5em;
          margin-bottom: 0;
          margin-left: 0;
          li {
            h4 {
              margin: 0; }
            padding: 0 .8em 0 0;
            margin-left: .8em;
            border-right: 1px solid #e5e5e5;
            &.start, &.end {
              border: 0;
              margin-right: 2em; }
            a {
              @include font-size(7);
              line-height: 1.5em;
              &.active {
                color: grey;
                pointer-events: none; } } } } } }

    li.bullet-item {
      padding: 1em 1em 0.90em;
      line-height: 1;
      border-bottom: 1px solid #edf1f2;
      a {
        &:hover {
          text-decoration: underline; } }
      h3 {
        width: 1.2em;
        @include font-size(14);
        opacity: 0.3; }

      &:nth-last-child(1) {
        border-bottom: 0; } } }


  ul.tasks-list {
    .calendar {
      @include font-size(60);
      margin: 0.25em 0;
      opacity: 0.35; }
    li.price {
      padding: 0.82em; } } }
