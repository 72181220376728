@import "~styles/mixins";

.page-canvas-add-template {
  z-index: 1000;
  position: absolute;
  border-radius: 23px;
  color: #919191;
  line-height: 1.5em;
  text-align: center;
  cursor: pointer;

  &.small {
    width: 100%;
    height: 7em;
    y: 25%;
    x: 0%;
    border-radius: 0px;
    font-size: 5em; }

  &.large {
    background: #ededed;
    font-size: 1.5em;
    width: 45%;
    height: 5em;
    y: 30%;
    x: 27.5%; }

  &:hover {
    color: $link-color; }

  i {
    margin-right: 0px; } }
