@import "~styles/mixins";

.form-builder-wrapper {
    .alert-box {
        @include pos(absolute,0,20%,auto,20%);
        z-index: 100; }

    .height-centered {
        @include pos(absolute,10%,10%,auto,10%);
        min-height: 20em; } }

.nn-form-editor-main {
    .input-width {
        width: 70%; }
    h5 {
        font-weight: 600; }
    input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea {
        margin: 0 0 0.5em;
        width: 70%; } }


.form-edit-header {
    padding: 5px 28px; }

.form-msg {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin: 4px 0px 0px 80px;
    border-radius: 5px;
    font-size: 14px; }

.errormsg {
    color: white;
    background-color: rgba(255, 68, 68, 0.95); }

.okmsg {
    background-color: rgb(132, 218, 163); }
