@import "mixins";

.nn-editor {
  .editor-header {
    position: relative;
    border: 1px solid #dee5e7;
    border-width: 0 0 1px 0;
    padding: 0;
    @include pos(absolute,0,auto,auto,0);
    width: 100%;
    z-index: 2;
    h5 {
      padding: 0;
      margin: 0.75em 0 0 0;
      &.storypad-title {
        margin: 0.44em 0;
        color: #828282;
        .favourites {
          color: #828282;
          opacity: 0.5; }
        &:hover {
          .favourites {
            opacity: 1; } } } }
    .header-items {
      vertical-align: middle;
      color: grey;
      margin: 0.43em 0;
      &.splitter-left {
        margin-left: 0.5em;
        border: 1px solid #dedede;
        border-width: 0 0 0 1px; }
      &.left-padding {
        padding-left: 0.5em; }
      h5 {
        padding: 0;
        margin: 0;
        @include font-size(7); } }
    label {
      line-height: 1.8em;
      cursor: default; }
    select.workflow, .select2-choice {
      border: 0;
      width: auto;
      background-color: white;
      color: $link-color;
      cursor: pointer;
      border-radius: 3px;
      @include font-size(7);
      padding: 0 1.25em 0.2em 0.5em;
      height: 24px;
      font-weight: 400;
      &.success {
        color: white;
        background-color: #499d49; }
      &.alert {
        color: white;
        background-color: orange; }
      &.error {
        color: white;
        background-color: #e74c3c;
        line-height: 1.8em !important; }
      &.secondary {
        color: white;
        background-color: #98a6ad; }
      &:hover {
        font-weight: 400; } }
    ul.select2-result-single {
      margin: 0;
      li {
        .select2-result-label {
          font-size: 0.875rem;
          line-height: 1.3em; } } }
    .select2-highlighted {
      background-color: #98A6AD; }
    .select2-container {
      margin-bottom: 0;
      text-align: left;
      .select2-choice {
        padding-top: 0.15em;
        color: white;
        background-color: rgba(152, 166, 173, 0.72);
        .select2-chosen {
          color: white; } }
      .select2-drop {
        padding-top: 0.5em; } }
    .switch {
      cursor: pointer; }
    .calendar {
      .fa-clock-o {
        color: #98a6ad; }
      @include font-size(7);
      .quickdate-calendar-header {
        padding: 10px 3px; }
      table.quickdate-calendar th, table.quickdate-calendar td {
        padding: 10px 5px; }
      .quickdate-button {
        margin: 0;
        padding: 0;
        text-decoration: none; }
      a {
        border: 0;
        background: transparent;
        color: grey; } }

    .fa-eye,.fa-download,.fa-flag, .page-pagination, .fa-info-circle, .fa-title, .fa-cog, .fa-floppy-o {
      padding: 0.25em;
      border: 1px solid transparent;
      margin: 0 0 0 0.5em;
      width: 32px;
      height: 32px;
      &:hover, &.active, &.red {
        background: transparent;
        -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
        border-color: #c2c2c2; } }

    .fa-title {
      margin: -1px 0 0 0.5em;
      padding: 0.43em 0.35em;
      border: 1px solid transparent;
      width: auto;
      height: auto;
      span {
        @include font-size(7); }
      .fa-eye, .fa-cog, .fa-floppy-o, .fa-flag, .fa-download {
        padding: 0;
        border: 0px solid transparent;
        margin: 0;
        width: auto;
        height: auto;
        &:hover {
          border: 0; } }
      &.active {
        .fa {
          box-shadow: none; } }
      &.nohover, &.nohover .fa {
        box-shadow: none;
        border: 0;
        margin-top: 1px; } }

    .fa-eye, .fa-eye-slash, .fa-search-plus {
      &.active,&:hover {
        color: $brand-bg; } }

    .fa-flag {
      &:hover {
        color: red; } }

    .fa-info-circle {
      padding: 0.36em 0.28em 0.35em 0;
      &:hover {
        color: $brand-bg; }
      &.active {
        color: $brand-bg;
        -webkit-box-shadow: inset 0 1px 2px 0 rgba(0,0,0,.1);
        box-shadow: inset 0 1px 2px 0 rgba(0,0,0,.1);
        background-color: white;
        border-color: #c2c2c2; } }

    .page-pagination {
      padding: 0.25em 0.45em;
      margin: 0 0 1px;
      width: auto;
      height: auto;
      -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
      background-color: #fff;
      border-color: #c2c2c2;
      &:hover {
        color: $link-color; } }
    .disabled {
      opacity: .5;
      pointer-events: none; } }
  .editor-details {
    @include pos(absolute,46px,0,0,auto);
    height: 100%;
    overflow-y: auto;
    border: 1px solid #dee5e7;
    border-width: 0 0 0 1px;
    padding-bottom: 4em;

    &.collapsePanel {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px); }
    &.expandPanel {
      -webkit-transform: translateX(0px);
      transform: translateX(0px); }

    &.narrow {
      .canvas-svg {
        height: auto !important; } }
    &.wide {
      width: 75% !important;
      max-width: 970px; }

    .breadcrumbs {
      margin: 2px 0 -2px 0;
      li {
        @include font-size(7);
        line-height: 2.7em;
        text-transform: none; } } } }
