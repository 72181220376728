@import "~styles/mixins";
.nn-chartbeat-list {
  .countdown {
    @include pos(absolute,0px,0px,quot,auto);
    background: none;
    opacity: 0.3; }
  li {
    display: table;
    vertical-align: middle;
    width: 100%;
    a {
      display: table-cell;
      line-height: 1.4em; } } }
