@import "~styles/mixins";

.nn-chartbeat {

  .publication-select {
    @include pos(absolute,0.58em,0.55em,auto,auto); }
  .select2-container {
    margin: 0; }

  ul.select2-result-single {
    margin: 0;
    li {
      color: #333;
      &.select2-highlighted {
        color: #000;
        background-color: #E0E0E0; }
      .select2-result-label {
        font-size: 0.875rem;
        line-height: 1.3em; } } }

  h6 {
    font-weight: 200; }
  .meter {
    width: 0; }
  canvas {
    width: 250px !important;
    height: 130px !important;
    margin: -1em 0 0.5em 0; }
  &:-moz-full-screen, {
    @include chartbeat-fullscreen; }
  &:-webkit-full-screen {
    @include chartbeat-fullscreen; }
  &:-ms-fullscreen {
    @include chartbeat-fullscreen;
    /* needed to center contents in IE */ }
  &:fullscreen {
    @include chartbeat-fullscreen; }

  .nn-chartbeat-stats {
    h2 {
      @include font-size(25); }
    .percentage {
      @include font-size(10);
      opacity: 0.8; }
    .social {
      @include chartbeat(#b2abd2); }
    .internal {
      @include chartbeat(#f7da9f); }
    .direct {
      @include chartbeat(#fd8d3c); }
    .links {
      @include chartbeat(#6baed6); }
    .search {
      @include chartbeat(#74c476); } } }
