@import "~styles/mixins";


.nn-explorer {

  /* Select2 Select box */
  .select2-choice {
    padding-top: 0.55em !important; }
  ul.select2-result-single {
    margin: 0;
    li {
      color: #333;
      &.select2-highlighted {
        color: #000;
        background-color: #E0E0E0; }
      .select2-result-label {
        font-size: 0.875rem;
        line-height: 1.3em; } } } }

.nn-page-explorer {

  /* drop down */
  .input-chevron {
    top: 13px;
    right: 17px; }

  .submenu-item.active {
    background: $panel-active-alt;
    .item-panel {
      border: 1px solid #f2f2f2;
      margin: 1em; } }
  .page-mainmenu {
    margin: 0; }

  /* select publication */
  .page-explorer-select {
    label {
      line-height: 2.5em; }
    select {
      @include font-size(7);
      border: 0;
      line-height: normal;
      margin-bottom: 1em;
      font-weight: bold; } }
  .selected-date {
    span {
      @include font-size(7);
      font-weight: bold; } }

  .page-explorer-input {
    label {
      line-height: 2.5em; } }

  /* calendar view */
  .issue-calendar {

    .quickdate-popup {
      display: block;
      position: static;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      background: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .selectOK {
      @include pos(absolute,292px,12px,auto,auto);
      width: auto;
      z-index: 10; }
    .quickdate-button {
      display: none; }
    .quickdate-text-inputs, .quickdate-close {
      display: none; }
    .quickdate-month {
      margin-top: 0.50em;
      color: #686868;
      @include font-size(7);
      font-weight: bold; }
    .quickdate-next-month {
      i {
        padding: 0; } }
    .quickdate-calendar-header {
      margin-bottom: 1px;
      float: left;
      width: 100%; }
    .quickdate-action-link {
      width: 35px; }

    table.quickdate-calendar, table.quickdate-calendar th, table.quickdate-calendar td {
      border: 0; }
    table.quickdate-calendar {
      th {
        background: $link-color;
        color: white;
        @include font-size(7); }
      td {
        @include font-size(7);
        background-color: #F9F9F9;
        color: #7d7d7d;
        font-weight: bold;
        &.other-month {
          background: #ebebeb !important; }
        &.disabled-date {
          pointer-events: none;
          cursor: normal; }
        &.highlighted-date {
          background: #00aeef;
          color: white; } }
      td:hover {
        opacity: 0.8; } } }

  .page-thumb-number {
    color: white;
    font-size: 0.8em;
    margin: -1em 0 0.5em;
    display: block;
    opacity: 0.7; }

  li.select-workflow {
    vertical-align: middle;
    color: grey;
    margin: .43em 0;
    select.workflow {
      width: auto;
      margin-right: 12.5px;
      border: 1px solid #98a6ad;
      background-color: white;
      color: $link-color;
      cursor: pointer;
      border-radius: 3px;
      @include font-size(7);
      padding: 0 1.25em 0.2em 0.5em;
      height: 24px;
      font-weight: 400;
      &.success {
        border: 0;
        color: white;
        background-color: #499d49; }
      &.alert {
        border: 0;
        color: white;
        background-color: orange; }
      &.secondary {
        border: 0;
        color: white;
        background-color: #98a6ad; } } } }
