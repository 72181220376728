.nn-date-range-picker {
  .rdrDefinedRangesWrapper {
    display: none; }
  .rdrDay {
      line-height: 1.5em;
      margin: 0; }
  .rdrDateDisplayItemActive input {
      color: #05a4ed; }
  .rdrDateDisplayItem input {
      font-weight: bold; }
  .select-date-range {
    border: 1px solid #ccc;
    padding: 0.5em;
    cursor: pointer;
    margin: 0 1em 1em 1em;
    position: relative;
    background: white;
    .toggle-btn {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    .range-picker-modal {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 9999;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: row; }
    .range-date-types {
      display: flex;
      flex-direction: column;
      padding: 1em 2em 1em 1em;
      .filter-type {
        display: flex;
        flex-direction: row;
        white-space: nowrap; } }
    .picker-clear {
      position: absolute;
      right: 1em; } } }
