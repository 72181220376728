.nn-report-events {
    table {
        width: 100%;
        margin: 0 5px;
        td {
            border-right: 1px solid #e4e4e4;
            &:last-child {
                border-right: none; } } }
    .padding-bottom {
        padding-bottom: 0.5em; }
    .count {
        font-size: 1.4em; }
    .border-bottom {
        border-bottom: 1px solid #e4e4e4; } }
