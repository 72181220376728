@import "~styles/mixins";

.editor-wrapper {
  float: none;
  display: flex;
  text-align: center;
  margin: 0 auto;
  height: 100%;

  &.spread {
    overflow-x: auto;
    .canvas-svg {
      width: 100%;

      &.right svg {
        float: left; }

      &.left svg {
        float: right;
        box-shadow: none !important; } } } }

.nn-page-canvas {
  background: transparent;
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  width: 100%; }

.canvas-svg {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  &.pageZoom {
    height: auto;
    overflow-y: auto;
    svg {
      height: auto; } }
  svg {
    background-color: white;
    box-shadow: 2px 2px 6px #d3d3d3;
    display: inline-block; } }

.editor-view {
  display: inline-block;
  height: 100%;
  width: 100% !important; }

.nn-page-editor {
  padding-top: 46px;

  .page-template-name {
    display: inline-block;
    height: auto;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis; }

  .page-editor-wrapper {
    position: relative;
    height: 100%;
    display: inline-block;

    .preview-fullpage {
      text-align: center;
      padding: 0.24em;
      width: auto;
      height: inherit; } } }

p.contentbox {
  color: grey;
  @include font-size(10);
  font-weight: 300;
  text-align: middle;
  margin: 0;
  &.status {
    text-transform: lowercase;
    font-style: italic;
    @include font-size(9);
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.77); } }

.page-pagination-container {
  user-select: none;
  -webkit-user-select: none;

  .page-pagination-button {
    width: 20px;
    display: inline-block; }

  .page-pagination-text {
    width: 103px;
    display: inline-block;
    text-align: center; } }

.copyfit path {
  stroke: rgba(0,0,0,.075); }
