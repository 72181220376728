@import "~styles/mixins";
.nn-story-snapshots {
  border: 0;
  @include font-size(9);
  padding: 0.55em 0;
  .list {
    @include nomargin;
    @include nopadding;
    li {
      float: left;
      min-height: 3em;
      width: 100%;
      &:hover {
        cursor: pointer; }
      &.latest, &.active {
        background: rgba(0, 174, 239, 0.11); } } }
  .snapshot-title {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .snapshot-header {
    @include font-size(6);
    padding-left: 3.3em;
    em {
      font-weight: normal; } }
  .snapshot-person {
    position: absolute;
    top: -6px;
    left: 12px;
    height: 32px;
    width: 32px;
    img {
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      height: 100%;
      width: 100%; } }
  .snapshot-label, .snapshot-time {
    display: inline-block; }
  .snapshot-label {
    .badges {
      padding: 0.3em;
      margin: 0 -0.8em;
      border-radius: 2px;
      @include font-size(6); } }
  .snapshot-annotation {
    color: #777;
    @include font-size(6);
    padding-left: 1em; }
  .snapshot-time {
    vertical-align: top; }
  .snapshot-body {
    p {
      @include font-size(6); } }
  .snapshot-channel {
    position: absolute;
    top: -.7em;
    right: 0;
    display: flex;
    .token {
      width: 2.5em;
      height: 2.5em;
      margin: .3em;
      border-radius: 100%;
      color: white;
      font-weight: 800;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      border: 2px solid #999;
      opacity: .15;
      &.available {
        opacity: .6; } }
    &.web-head {
      .token.web {
        &.available {
          &.published {
            opacity: 1;
            border: 4px dotted #fff;
            background-color: #499d49;
            color: white; }
          &.waiting {
            border: 2px dotted #fff;
            opacity: 1;
            background: #fcbe4a;
            color: white; } } } }
    &.print-head {
      .token.print {
        &.available {
          &.published {
            opacity: 1;
            border: 4px dotted #fff;
            background-color: #499d49;
            color: white; }
          &.waiting {
            border: 2px dotted #fff;
            opacity: 1;
            background: #fcbe4a;
            color: white; } } } } }
  .load-more {
    padding: 0.5em 1em 0.5em 1em;
    margin-left: 1em; }
  .red-flag {
    padding-bottom: 5px;
    margin-top: -7px; } }
