.print-stories-container {
    margin: 40px auto;
    border: 1px solid #ddd;
    width: 80%;

    .heading {
        display: flex;
        justify-content: space-between; }

    .publication-name, .fetching {
        padding: 10px; }

    .fetching, .empty {
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto; }

    .refetch {
        padding: 10px;
        height: 40px;
        width: 40px;
        margin: auto 10px;
        border-radius: 4px; }

    .table-wrapper {
        overflow-y: scroll;
        max-height: 50vh;
        display: block; }

    .story-table {
        width: 100%;
        border: unset;
        margin-bottom: unset;

        .headers {
            background-color: #CEE2F4;
            position: sticky;
            top: 0; }

        .re-processing, .processing {
            color: #CC0000; }

        .completed {
            color: #74C476; }

        .separation {
            border-left: 1px solid #e2e2e2; }

        .center {
            text-align: center; } } }
