@import "mixins";
.nn-explorer {
  .explorer-panel-listbox {
    @include pos(absolute,47px,0,0,0);
    width: 100%;
    overflow-y: auto;
    margin-bottom: 1em;
    padding: 0.5em 0.5em 3em;

    &.topbar {
      top: 108px; } }
  .advanceBox {
    .explorer-panel-listbox {
      &.topbar {
        top: 280px; } } }
  ul {
    li.toggle-panel {
      left: $nav-width; } }
  .breadcrumbs {
    li {
      @include font-size(8);
      line-height: 2.3em;
      text-transform: none; } } }

