@import "~styles/mixins";

#container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;

  #aside-container.top-margin {
    margin-top: 45px; }

  #aside-container {
    padding: 0;
    background-color: white;
    position: absolute;
    right: 0;
    height: auto;
    left: auto;
    overflow: auto;
    top: 0;
    width: 100%;
    bottom: 0px;
    z-index: 0;
    -webkit-box-shadow: -2px 0 20px 0 rgba(0,0,0,0.25);
    box-shadow: -2px 0 20px 0 rgba(0,0,0,0.25);

    h4 {
      background: white;
      clear: both;
      padding: 0.5em 2em;
      border: 1px solid #DEE5E7;
      border-width: 0 0 1px; }
    h6 {
      color: #666;
      padding: 1.25em 0 0px; }

    .closePanel {
      color: #9B9B9B;
      padding: 0.5em;
      clear: both;
      margin: 0.5em;
      border: 1px solid transparent;
      &:hover {
        border: 1px lightgrey solid; } }

    .resizePanel {
      color: #9B9B9B;
      padding: 0.5em;
      clear: unset;
      margin: 0.5em;
      border: 1px solid transparent;
      &:hover {
        border: 1px lightgrey solid; } } }

  &.page-info {
    width: 400px;
    right: -490px; }

  &.module-info {
    width: 370px;
    right: -450px;
    -webkit-box-shadow: -19px 0 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: -19px 0 20px 0 rgba(0, 0, 0, 0.15); }

  &.aside-in {
    display: block;
    left: auto;
    right: 0; } }


#container #aside a:not(.btn) {
  &:hover, &:focus {
    color: #373737; }
  color: #515151; }

#aside a:not(.btn):not(.btn-link) {
  color: inherit; }


.list-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5em; }

.list-group-item {
  background: white;
  clear: both;
  padding: 0 0 0 1em;
  display: block;
  color: #222;
  font-size: 1.12em;
  .label {
    background-color: transparent;
    color: #222; }

  &.line {
    border: 1px solid #DEE5E7;
    border-width: 1px 0 0;
    .media-body {
      border: 0; } }

  &.cursor-pointer {
    &:hover {
      color: $link-color; } }


  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; } }

.media-left {
  padding: 2px 1em 9px 0;
  display: none;
  span.fa-stack {
    vertical-align: middle;
    width: 28px;
    font-size: 1.1em;
    height: 28px;
    padding-top: 3px;
    .fa-stack-2x {
      font-size: 1.7em;
      background: transparent; }
    .fa-stack-1x {
      font-size: 0.8em;
      line-height: 2em;
      .story-info & {
        line-height: 1.7em; }
      color: #afafaf;
      .task-error & {
        color: #e74c3c !important; } } }
  &.gravatar-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    max-width: 32px; } }

.media-body {
  width: 90%;
  position: relative;
  margin: 0 auto;
  padding: 0.25em 0;
  .last & {
    border-color: white; }
  color: #666;
  a {
    color: #666; }
  .description {
    outline: none;
    height: auto;
    display: block;
    width: 100%;
    @include font-size(7);
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    transition: all .3s;
    color: #666;
    &.line {
      border-bottom: 1px solid #9e9e9e; } }
  label, .page-element-label {
    color: #666666;
    font-size: 0.85rem;
    cursor: text;
    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -ms-transition: .2s ease-out;
    transition: .2s ease-out;
    opacity: 0.8;
    padding-bottom: 0.1em;
    vertical-align: top; }
  textarea, &.description {
    line-height: 1.3em;
    @include font-size(7);
    padding: 0.5em !important;
    box-sizing: border-box !important; }

  input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, textarea:focus {
    box-shadow: none; }
  input[type="text"] {
    box-shadow: none;
    border-color: #e6e6e6; }
  .tasklist-assignee {
    height: auto;
    cursor: pointer; }
  .btn-options {
    @include pos(absolute,-4px,0,auto,auto);
    .btn-left {
      @include font-size(7);
      padding: 0.5em;
      float: left;
      margin-left: 2px;
      display: block;
      margin: 0 auto;
      text-align: center;
      span {
        @include font-size(5);
        display: block;
        margin-top: 2px;
        opacity: 0.5; }
      &:hover, &.active {
        background: #e6e6e6; } } }

  .overflow-ellipsis {
    width: 100%;
    display: inline-flex; }

  &.redflagged-switch {
    padding: 0.75em 0;
    .page-element-label {
      opacity: 1;
      line-height: 1;
      float: left;
      vertical-align: middle;
      float: left;
      span {
        vertical-align: middle;
        display: inline-block;
        margin-top: -1.4em; } }
    .switch {
      input.cmn-toggle-round-flat:checked + label, input.cmn-toggle-round-flat + label {
        height: 29px;
        width: 50px; }
      input.cmn-toggle-round-flat + label:after {
        width: 20px; } } }

  &.summary {
    i.fa-circle {
      padding: 0.6em 0; } } }





.media-body, .media-left, .media-right {
  vertical-align: middle; }

.assignStory {
  .page-element-label {
    opacity: 1;
    float: left; } }

.searchStoryInput {
  margin-top: -11px;
  .page-element-label {
    display: none; }
  .close-tip {
    top: 23px !important; }
  .searchBox {
    border-width: 4px; } }

.page-element-search {
  background-color: white;
  input {
    box-shadow: none !important; } }
.page-element-search-result {
  z-index: 1;
  top: 0.5em;
  i {
    margin-left: -1em; } }

.page-elements-item-summary {
  .list-group-item {
    padding: 0.25em 0 0;
    background: white; }
  .meter, .progress {
    height: 1px; } }

.furniture-upload-button {
  top: 15px;
  right: 20px; }
/* Search Media */

.media-list {
  padding: 1em;
  column-gap: 1em;
  font-size: 0.85em;
  column-count: 2;
  -webkit-column-count: 2;
  -webkit-column-gap: 1em;
  background: #F6F8F9;
  &.preview {
    -webkit-column-count: 1; }
  .media-item {
    background: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    transition: all .3s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    border-radius: 6px;
    display: inline-block;
    background: #fff;
    margin: 0 0 1em;
    width: 100%;
    box-sizing: border-box;
    span {
      line-height: 1.3em;
      margin-bottom: 0.4em;
      font-size: 0.8em;
      white-space: normal; }
    .title {
      font-size: 1em;
      font-weight: 300; }
    img.preview {
      width: 100%;
      height: auto;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px #F5F5F5 solid; }
    .person.profile {
      width: 100%;
      text-align: center;
      margin-top: -20px;
      img {
        width: auto; } }
    .wrapper {
      min-height: 30px;
      &.noImage {
        min-height: 30px;
        background-color: #F5F5F5;
        padding: 0.25em 0;
        .person.profile {
          margin-top: 0.25em; } } }
    time {
      color: #999;
      font-size: 0.8em;
      white-space: normal;
      line-height: 1; }

    .byline {
      text-align: center;
      @include font-size(5);
      vertical-align: bottom;
      display: block;
      line-height: 1.5em; }

    .task-assigned {
      padding: 0.5em 0.5em 0.5em 1.5em;
      border-radius: 5px;
      @include font-size(5);
      margin: 2px; }

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.82); }

    .filetype {
      background: rgba(0, 0, 0, 0.4);
      border: 2px solid rgba(255, 255, 255, 0.68);
      border-radius: 15px;
      -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      color: #fff;
      cursor: pointer;
      font-size: 0.75em;
      font-weight: bold;
      padding: 5px 10px;
      margin: 0.5em 0 0 .3em;
      float: left; }

    &:hover {
      cursor: pointer; } } }

.task-search-label {
  padding: 0px 10px;
  border-bottom: 1px solid #ededed; }
select.task-search {
  background-color: #ffffff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAgUlEQVQ4je3QSwrCQBCE4dxC4pWyCkoWepIcIecTCV7FCJ+bAZthMj4QV9Zyuv6q7mmav74nTDihrXja5JnyweihuRSS4Dn4xjjssayFFOAFfd6wxzWYLthig3N4v+G4duNQ2CRvHp59Zr5JbD5U4UrI63AI2aWQ9+EQ0qH7CP6Z7vfaArEw63dxAAAAAElFTkSuQmCC);
  background-position: 97% center;
  overflow: auto;
  border: 0;
  width: 100%;
  padding: 0;
  font-weight: 400;
  max-width: 80px;
  cursor: pointer; }







/* generic Nathans styles */


.select2-result-single {
  margin: 0; }

.text-thin {
  font-weight: 300; }

.bg-trans {
  background-color: transparent; }

.bg-white {
  background-color: white; }

.text-lg {
  font-size: 0.9em;
  line-height: 1.9;
  &.link {
    color: $link-color; } }

input[type="text"][disabled].text-lg {
  background: transparent; }

input.text-lg {
  border-width: 0 0 0 0;
  height: 1.5rem;
  background: transparent;
  box-shadow: 0px 0px 0px 0px !important; }

.list-button {
  background-color: #FCFCFC;
  border: 1px solid #EDEDED;
  font-weight: 500;
  color: #4E4e4e;
  font-size: 0.9em !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.taskFooter {
  float: left;
  width: 100%;
  border-top: 1px solid #e2e2e2;
  margin-top: 1em; }

.removeTask {
  color: $link-color;
  @include font-size(7);
  line-height: 0.8em;
  display: block;
  float: left;
  margin: 1.8em auto auto 1.2em;
  &:hover {
    text-decoration: underline; }
  .story-info & {
    display: none; } }

.taskStoryLinks {
  @include font-size(7);
  line-height: 1.5em;
  margin-left: .5em;
  a {
    color: $link-color;
    &:hover {
      text-decoration: underline; } } }

.close-tip {
  position: absolute;
  right: 12px;
  top: 20px;
  color: #b2b2b2 !important;
  text-decoration: none;
  font-weight: 300;
  line-height: 0;
  z-index: 1;
  top: 46px;

  &.workflow {
    top: 64px; }

  .page-elements-summary & {
    top: 20px; }

  &:hover {
    color: #05759e !important; } }

.page-element-input, a.text-lg, input.text-lg, textarea, div.text-lg.quickdate, .page-element-validation {
  background-color: #f9f9f9;
  color: #666;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  outline: none;
  min-height: 1.5rem;
  max-height:  9rem;
  line-height: 1.5rem;
  display: block;
  width: 100%;
  @include font-size(7);
  margin: 0;
  padding: 0.5em;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all .3s;

  &:focus {
    border: 1px solid #6AB4FD; } }

.media-body div {
  input, textarea, div {
    &.ng-invalid, &.ng-invalid-min-date {
      border: 1px solid rgba(231, 76, 60, 0.5);
      background-color: #f9f9f9 !important; } } }

.text-link {
  @include font-size(7);
  display: inline-block;
  //padding: 0.75em 0
  margin: -4px 0 0 0.5em;
  line-height: 1.7em;
  vertical-align: middle;
  display: initial;

  &.hover:hover {
    text-decoration: underline; } }

.page-element-validation {
  float: left;
  margin-top: 0.25em;
  padding-bottom: 0.15em;
  padding: 0 1.5em 0.15em 0;
  box-sizing: border-box;
  i {
    @include font-size(7);
    font-style: normal; } }

.page-element-input.contenteditable {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  z-index: 1;
  line-height: 1.2em;
  padding-bottom: 2em; }


.page-element-detail {
  .quickdate > a {
    display: block; }
  .quickdate-popup {
    width: 100%;
    margin-left: -0.55em;
    label {
      position: static; } }
  .quickdate-date-input, .quickdate-time-input {
    margin: 0; } }

.page-element-header, .page-summary-header {
  min-height: 3em;
  background-color: #f6f8f9;
  width: 100%;
  float: left; }
.page-elements-action {
  button, .button {
    font-size: 0.9em !important; } }

.iframe-element-header {
  min-height: 3em;
  width: 100%;
  float: left; }

.runsheet-infopanel {
  counter-reset: idea;
  .runsheet-wrapper {
    background: #ebedee;
    padding: 0.3em 0;
    float: left;
    width: 100%; }

  .runsheet-items {
    position: relative;
    .list-group-item {
      padding: 0 1em 0 2.6em; }
    .item-title {
      white-space: normal;
      max-height: 4rem;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left; }
    .item-title.expand {
      max-height: none;
      height: auto; }
    .item-finished-title {
      max-height: 4rem;
      text-decoration: line-through; }
    &:before {
      counter-increment: idea 1;
      content: counter(idea);
      position: absolute;
      top: 50%;
      left: 0.55em;
      font-weight: 200;
      color: #ccc;
      transform: translate3d(0, -50%, 0);
      font-size: 2em; } } }

.page-elements-advertisement, .page-elements-summary, .page-elements-info, .page-elements-share {
  .container-wrapper {
    border: 1px solid #DEE5E7;
    border-width: 0 0 1px; }
  .list-group-item {
    border: 1px solid #DEE5E7;
    border-width: 1px 0 0;
    padding: 0 1em;
    &:hover {
      background: transparent;
      cursor: default; } }
  .media-left {
    display: inline-block;
    padding: 0.5em; }
  .media-body {
    display: inline-block; } }

.page-elements-advertisement, .page-elements-info {
  .media-left {
    span {
      padding-top: 0; } }
  .media-body {
    width: 70% !important;
    .page-element-label {
      margin-bottom: -8px; }
    .description {
      font-weight: 500; } }
  .list-group-item {
    border: 0; } }


/* infopanel top level */
.page-elements-summary {
  .media-left {
    padding: 0 0.2em 0 0.5em; }
  .media-body {
    border: 0;
    width: 100%;
    .label {
      margin-left: 0.25em; } }
  .list-group-item {
    padding: 0 1em 0 1.85em; }
  &.story-info {
    .media-body {
      width: 83%; }
    .list-group-item {
      padding: 0 0 0 1em; } } }

/* infopanel Page info  */
.page-elements-info {
  .list-group-item {
    padding: 0 1em !important; }
  .media-body {
    .label {
      margin-left: 0.25em; } } }


.page-copyfit-assistance {
  margin: 0px;
  padding: 0px;
  flex: 1 1 0%;
  max-width: 250px;
  .select2-results {
    .select2-result-label {
      font-size: 0.8em !important;
      text-align: left; } } }


.copyfit-generate-button {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)); }


.copyfit-generate-button:hover, .copyfit-generate-button:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)); }

.copyfit-generate-button:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)); }

/* infopanel Page share  */
.page-elements-share {
  .select2-results {
    .select2-result-label {
      font-size: 0.8em !important;
      text-align: left; } }
  .container-wrapper {
    border-width: 0 !important; }
  table.shared-pages {
    border-width: 1px 0 0 0;
    width: 100%;
    margin-bottom: 0;
    thead {
      .pub {
        width: 170px;
        max-width: 170px;
        text-overflow: ellipsis; } }
    tr {
      .nn-publication-select {
        .select2-container {
          .select2-chosen {
            max-width: 120px; } } }
      .pub {
        width: 150px;
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      th.shared-pages-header {
        font-size: 0.8em;
        padding: 1em 0;
        white-space: nowrap;
        font-weight: normal;
        color: #424242; }
      &.active {
        background: #98a6ad;
        td {
          color: white; } }
      td {
        font-size: 0.82em; } } } }

.page-elements-newslist {
  .list-group-item {
    cursor: move;
    min-height: 40px;
    max-height: 4rem;
    transition: 0.2s all ease;
    overflow: hidden; }
  .list-group-item.expand {
    height: auto;
    max-height: none; }

  .idea-description {
    margin-bottom: 20px; }

  td.page {
    width: 50px;
    .select2-container {
      width: 50px; }
    .select2-drop-active {
      right: 0; }
    .select2-container-disabled {
      display: none; } }
  td.remove {
    width: 150px;
    fa.green, fa.red {
      opacity: 0.8;
      padding: 5px;
      &:hover {
        border: 1px solid #ddd; } } }

  .select2-container {
    margin: 0;
    max-width: 120px;
    box-shadow: none;
    background-color: transparent;
    a {
      box-shadow: none;

      &.select2-choice {
        font-size: 0.9em;
        line-height: 10px;
        height: 2em;
        border: 1px solid #ddd !important;

        .select2-chosen {
          text-align: left;
          line-height: 1.2em;
          margin: -0.35em 0 0 0;
          padding: 0; } } }
    .select2-drop-active {
      width: 200px;
      top: 35px;
      padding-top: 15px;
      border: 1px solid #DEE5E7; } }

  .select2-results {
    .select2-result-label {
      font-size: 0.8em;
      text-align: left; } }

  .quickdate {
    position: relative;
    .quickdate-popup {
      position: absolute;
      right: -7em;
      top: 4em;
      th {
        text-align: center !important; }
      td {
        font-size: 0.9em; } } }
  .quickdate-button {
    margin: 0;
    height: 1.9em;
    line-height: 1;
    font-size: 0.9em;
    padding-right: 3px;
    padding-left: 3px; } }

/* infopanel advertisement info  */
.page-elements-advertisement {
  .list-group-item {
    padding: 0 0.5em; } }

/* infopanel validation info  */

.page-element-item-validation {
  .list-group-item {
    padding: 0 0.5em;
    background: #fff;
    &:hover {
      background: transparent;
      cursor: default; } }

  .media-left {
    display: table-cell;
    padding: 2px 1.5em 9px 0.5em; }
  .media-body {
    display: table-cell;
    width: 100%;
    padding: .25em 0;
    border-bottom: 1px solid #e9e9e9;
    border: 0;
    padding: 0;
    .page-element-validation {
      margin: 0;
      padding: 0 1.5em .15em 0;
      background: #fff;
      border: 0;
      box-sizing: border-box; } }
  .percentageValue {
    position: absolute;
    top: 0.35em;
    left: -3em;
    font-style: normal;
    @include font-size(10);
    font-weight: 200;
    opacity: 0.8;
    line-height: 1;
    width: 50px;
    overflow: hidden;
    text-align: center; }
  .count {
    @include font-size(6);
    font-weight: normal;
    color: grey; }
  .progress, .meter {
    border: 0;
    padding: 0;
    height: 4px;
    clear: both;
    display: block;
    &.secondary {
      .meter {
        background-color: #FFBC0F; } } } }

.page-figures-validation {
  span.title {
    line-height: 1;
    font-size: 0.85em;
    padding: 0 0 3px 5.3em;
    display: block; }
  .list-group-item {
    padding: 0 0.5em 0 2.5em; }
  .media-left {
    padding: 0 0.25em 9px 1.3em;
    span.fa-stack .fa-stack-1x {
      line-height: 1.5em; } }
  .percentageValue {
    top: 1.3em;
    left: -4.4em; } }


.story-info {
  .storySelection, .storyWorkflow {
    display: none; } }



.media-body div.select2 {
  margin: 0;
  width: 100%;
  .select2-choice {
    box-shadow: none;
    border: 0;
    background: #fafafa;
    color: #666; } }

.no-outline {
  border: 0px !important;
  box-shadow: 0px 0px !important; }

.media, .media-body {
  zoom: 1; }

#container #aside .text-muted {
  color: #9e9e9e; }



select.select-workflow {
  line-height: normal;
  font-size: 0.875rem;
  background: #fafafa;
  border: 1px solid #e2e2e2;
  margin: 0;
  color: #666;
  .disabled {
    color: white; } }

.select2-width {
  max-width: 130px; }

/*select.select-workflow */
/*  color: #666 */
/*  background-color: transparent */
/*  background-image: none */
/*  overflow: auto */
/*  border: 0 */
/*  line-height: normal */
/*  margin: 0 */
/*  height: 1.4em */
/*  padding: 0 */
/*  font-size: 0.785em */
