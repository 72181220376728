@import "~styles/mixins";

.nn-publication-editor {
  .capitalise {
    text-transform: uppercase; }
  .float-right-padding {
    padding-right: 0.9375rem; }
  .float-left-padding {
    padding-left: 0.9375rem; }
  .section {
    height: 2.3125rem;
    line-height: 2.3125rem;
    font-weight: bold; }
  .select2-result-single {
    margin: 0; }
  .overflow-y-scroll {
    overflow-y: auto;
    max-height: 100%; }
  .remove-btn {
    height: 2.3125rem;
    line-height: 2.3125rem;
    padding-top: 0; }
  .column-heading {
    font-size: 0.8rem;
    line-height: 1.6rem; }
  .field-message {
    font-size: 0.7rem;
    line-height: 1rem;
    padding-bottom: 0.5rem; }
  .marginleft {
    margin-left: 0.7em; }
  .filename {
    font-size: 0.9rem;
    line-height: 2.3125rem;
    min-height: 37px; }
  .progress {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(100, 100, 100, 0.15); }
  .selected {
    background: #F7F7F7;
    opacity: 0.8; }
  .day {
    border-top: 1px solid #F2F2F2;
    padding-top: 20px; }
  .preview {
    margin-top: -12px; }
  .subheading {
    font-size: 1.3rem; }
  .truncate {
    max-width: 200px;
    display: inline-block;
    @include text-truncate; }
  .inline-label {
    font-size: large; } }
