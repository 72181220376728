@import "~styles/mixins";
.nn-comment-list {
  border: 0;
  @include font-size(9);
  textarea {
    @include font-size(6); }
  .comment-button {
    margin: 0.4em 0 0.8em; }
  .comments-header {
    @include font-size(6);
    margin-top: 0.15em;
    padding-left: 3.3em;
    time {
      margin-top: 0.75em; } }
  .comments-person {
    position: absolute;
    top: 31px;
    height: 32px;
    width: 32px;
    img {
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      height: 100%;
      width: 100%; } }
  .comments-label, .comments-time {
    display: inline-block; }

  .comments-annotation {
    color: #777;
    @include font-size(6);
    padding-left: 1em; }
  .comments-time {
    vertical-align: top; }
  .comments-body {
    margin-top: 1em;
    p {
      @include font-size(6); } } }


