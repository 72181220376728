@import "~styles/mixins";

.nn-story-list {
  width: 100%;
  .story-assign-print {
    @include font-size(5);
    border: 1px solid #dedede;
    padding: 1px 6px 0;
    border-radius: 3px;
    @include pos(absolute,auto,2px,1.35em,auto);
    border-color: lightgrey;
    z-index: 10;
    background: white;
    &:hover {
      opacity: 0.8;
      background: darkgrey;
      color: white; } }
  li.container-overflow.list {
    &:hover {
      background-color: #f2f2f2 !important; } }
  .lead {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    time {
      position: static;
      text-align: right;
      width: 100%; }
    .favourites {
      position: static;
      margin-right: 0.5em; } }
  .content {
    display: flex;
    flex-direction: row;
    padding: 0.5em 0;
    .title {
      margin: 0;
      font-weight: bold; }
    .text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-right: 1em; }
    .image {
      width: 80px;
      flex-shrink: 0;
      text-align: right; } }
  .meta {
    display: flex;
    flex-direction: row;
    .channels {
      display: flex;
      flex-direction: row;
      color: white;
      font-size: 0.8em;
      font-weight: bold;
      gap: 0.5em;
      flex-grow: 1;
      justify-content: flex-end;
      text-transform: capitalize;
      align-items: flex-start;
      .story__unpublished {
        background: #98a6ad;
        border-radius: 2px;
        padding: 0 0.4em; }
      .story__published {
        background: #499d49;
        border-radius: 2px;
        padding: 0 0.4em; } } }
  .copy {
    padding-top: 0.5em;
    z-index: 100; } }
.task-form-favourites {
  top: 0.45em !important;
  left: 1.5em !important; }

