/*  imported from StorySocial.sass  */

.StorySocial__storySocialImage___1jiHy {
  border: 3px solid transparent;
  float: left;
  width: 85px;
  height: 85px;
  position: relative;
  marginRight: 5px;
  marginBottom: 5px; }
  .StorySocial__storySocialImage___1jiHy span {
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    bottom: 5px;
    right: 5px;
    color: #007aff;
    display: none; }
  .StorySocial__storySocialImage___1jiHy.StorySocial__active___3eB7j {
    border: 3px solid #007aff; }
    .StorySocial__storySocialImage___1jiHy.StorySocial__active___3eB7j:hover {
      border: 3px solid #007aff; }
    .StorySocial__storySocialImage___1jiHy.StorySocial__active___3eB7j span {
      display: block; }
  .StorySocial__storySocialImage___1jiHy:hover {
    border: 3px solid #ebebeb; }
