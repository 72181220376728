$break-small: 744px;

#storypad {
  // NewsNow theme
  background-color: #f0f3f4 !important;

  // global

  .cursor-pointer {
    cursor: pointer; }

  .grey-light1 {
    color: #98a6ad !important; }

  .brand-color-hover {
    &:hover {
      color: #00aeef !important; } }

  .transparent {
    background-color: transparent !important; }

  .nopadding {
    padding: 0 !important; }

  .nomargin {
    margin: 0 !important; }

  .marginleft {
    margin-left: 1em !important; }

  .greylightbg2 {
    background-color: #f6f8f8; }

  button.primary, a.primary, a.cancel, a.clone {
    background: #05759e; }

  button.primary {
    width: 32px;
    height: 32px;
    white-space: nowrap; }


  .cke_button__spellchecker {
    display: none !important; }

  .expand-width {
    left: 150px !important;
    right: 225px !important;
    transition: all 0.2s ease; }


  #storypad-control-bar {
    .icon-download-alt.icon-white {
      background-image: url(/images/save.png);
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0.15em 0.25em;
      margin: 3px 0 0 7px;
      &:hover, &.active {
        background-image: url(/images/save-hover.png); } } }


  .gallery-top-close {
    position: absolute !important;
    top: 0.75em !important;
    background: #05759e !important; }

  a.cancel {
    background: #98a6ad; }

  .empty {
    height: 45px; }

  .paper-wrapper {
    background-color: #f0f3f4 !important;
    #paper {
      padding-top: 1px;
      padding-bottom: 1px;
      box-shadow: 2px 2px 6px lightgrey; } }

  #story-app {
    &.narrow {
      width: 75%;
      float: left;
      transition: all 0.2s ease; }
    .closePanel {
      display: none; } }

  #story-media {
    right: -70em !important;
    left: auto;
    transition: all 0.2s ease;
    &.expand {
      right: 0 !important;
      @media screen and (max-width: $break-small) {
        left: 0; } } }

  #story-widgets {
    left: -70em !important;
    transition: all 0.2s ease;
    .panelsForSmartphone {
      display: none !important; }
    @media screen and (max-width: $break-small) {
      height: 100%;
      .story-elements-wrap {
        position: static; } }
    &.expand {
      left: 0 !important; }


    .story-elements-wrap {
      top: 0; } }

  // Styles for Storypad-header
  .storypad-header {
    border: 1px solid #dee5e7;
    border-width: 0 0 1px 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    height: 44px;
    .wrapper {
      .storypad-header__items {
        padding-left: 0.95em;
        width: auto;
        float: right;
        &.heading {
          float: left; }
        @media screen and (max-width: $break-small) {
          float: left; } }

      h5 {
        font-family: 'Helvitica', sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 3em;
        color: #05759e;
        @media screen and (max-width: $break-small) {
          display: none; } }
      .span9 {
        text-align: right;
        float: right; }
      .wrapper-action-buttons {
        margin: 0.35em 9em 0.35em 1.5em;
        .elements-panel, .images-panel {
          font-size: 14px;
          overflow: hidden;
          padding: 0 0.5em 0 0em;
          float: left;
          width: auto; }
        @media screen and (max-width: $break-small) {
          margin-right: 0; } } } }


  // Controls buttons
  #storypad-control-bar.sticky-panel {
    top: -1px;
    position: fixed;
    bottom: auto;
    right: 1.5em;
    z-index: 100;
    width: auto;
    height: auto;
    background: transparent;
    li.story-submit-wrap, li.story-settings-wrap {
      padding: 0.45em 0;
      margin-left: 1em; }
    li.story-status-wrap {
      display: none; }
    li.story-submit-wrap {
      float: right; } }



  // Settings panel
  .settings-panel-wrapper {
    .form-settings {
      bottom: 45px;
      @media screen and (max-width: $break-small) {
        top: 45px; } }
    .footer {
      top: 2px;
      bottom: auto;
      position: fixed;
      right: 1em;
      width: 100%;
      background: #f6f8f8;
      height: auto;
      z-index: 1000;
      padding-bottom: 3px;
      .buttons {
        margin: 0.3em 0 0 0;
        float: right;
        a {
          padding: 7px 18px; }
        .save {
          float: right; }
        .cancel {
          float: left;
          margin-right: 5px; } } } }

  // settings icons

  // CK Editor placement
  #cke_story-body-wrapper {
    margin-left: -4em;
    &.narrow {
      margin-left: -18em; }
    @media screen and (max-width: $break-small) {
      margin-left: 0; } }


  // StoryPad changes



  #storypad-edit-element[data-element="galleryElement"] {
    .storypad-modal-fg {
      top: 0 !important;
      .modal-body {
        overflow: auto;
        .gallery-elements {
          height: 20em; }
        .gallery-meta {
          position: static;
          margin-top: 25em;
          overflow: auto; } } } }

  #addzone.in {
    bottom: auto;
    top: 180px; } }
