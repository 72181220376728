.nn-story-toolbar {
  select.storyStatus, select.storyVisibility, select.storyChannel {
    cursor: default !important;
    background-image: none;
    pointer-events: none; } }
.non-clickable {
  opacity: 0.5;
  border: none !important; }
.non-clickable:active {
  pointer-events: none; }
.copy-dialog {
  display: flex;
  justify-content: center;
  align-items: center; }
@mixin dialog-header-footer {
    display: flex;
    flex-direction: row;
    align-items: center; }
@mixin dialog-footer {
    padding: 0px;
    height: 30px; }
.dialogTitle {
  @include dialog-header-footer;
  justify-content: flex-start; }
.dialogFooter {
  @include dialog-header-footer;
  justify-content: flex-end;
  padding-top: 15px; }
.copy-link-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: black;
  width: 800px;
  -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  padding: 10px 30px; }
.dialog-url {
  display: flex;
  flex-direction: row; }
@mixin dialog-url-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
.url-container-left {
  @include dialog-url-container;
  width: 120px;
  height: 30px;
  background-color: #2d2d2d; }
.url-container-left-text {
  color: white;
  margin: 0px;
  padding-left: 15px; }
.url-container-right {
  @include dialog-url-container;
  width: 650px;
  height: 30px;
  background-color: #404040;
  text-overflow: ellipsis;
  white-space: nowrap; }
#StoryLink {
  height: 30px;
  margin: 0px;
  background-color: #454545;
  color: white;
  border: none; }
.dialog-footer-close {
  @include dialog-footer;
  width: 100px;
  background-color: #4b4b4b;
  font-size: 0.8rem !important; }
.dialog-footer-copy-link {
  @include dialog-footer;
  width: 120px;
  font-size: 0.8rem !important; }
.tooltip {
  visibility: hidden;
  position: absolute;
  margin-left: -40px;
  margin-top: 6px;
  font-size: smaller; }
.tooltip .tooltiptext {
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 6px;
  position: absolute;
  top: 100%; }
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent; }
#web-link:hover + .tooltip {
  visibility: visible; }
.highlight {
  background-color: #f9ecd8 !important; }
.social-media-share {
  height: 140px;
  width: 100%;
  background-color: #2d2d2d;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
.social-media-logo {
  width: 80px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer; }

