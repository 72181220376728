// THEME

$popup-font-color: #333;
$button-bg-color: #fff;
$button-text-color: #333;
$button-border-color: #ccc;
$popup-bg-color: #fafafa;
$popup-border-color: #ddd;
$calendar-bg-color: #fff;
$selected-date-bg-color: #b0ccde;
$today-font-color: #B58922;
$error-color: #DD3B30;

.quickdate {
  display: inline-block;
  vertical-align: bottom;
  font-size: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  input, select {
    font-size: 13px; } }

.quickdate-button {
  background: $button-bg-color;
  color: $button-text-color;
  border: solid 1px $button-border-color;
  box-shadow: outset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    i {
      text-decoration: none; } }
  i {
    padding-right: 4px; } }

.quickdate-popup {
  color: $popup-font-color;
  font-size: 15px;
  background-color: $popup-bg-color;
  border: solid 1px $popup-border-color; }

.quickdate-action-link:visited, .quickdate-action-link:hover {
  color: $popup-font-color; }

.quickdate-next-month i {
  padding-left: 10px; }

.quickdate-prev-month i {
  padding-right: 10px; }

table.quickdate-calendar {
  border: solid 1px #ccc;
  background-color: $calendar-bg-color;
  th, td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc; }
  td {
    &:hover {
      background-color: darken($calendar-bg-color, 10%); }
    &.other-month {
      background-color: darken($popup-bg-color, 12%);
      color: lighten($popup-font-color, 30%);
      &:hover {
        background-color: darken($popup-bg-color, 20%); } }
    &.disabled-date {
      pointer-events: none;
      cursor: normal;
      background-color: darken($popup-bg-color, 6%);
      color: darken($popup-bg-color, 30%); }
    &.selected {
      background-color: $selected-date-bg-color;
      font-weight: bold; }
    &.is-today {
      color: $today-font-color;
      font-weight: bold;
      &.disabled-date {
        color: lighten(desaturate($today-font-color, 75%), 15%);
        font-weight: normal; } } } }

.quickdate-popup-footer {
  margin: 3px 1px 0; }

.quickdate-button {
  display: inline-block;
  background-color: $button-bg-color;
  color: $button-text-color;
  border: solid 1px $button-border-color;
  box-shadow: outset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
  text-decoration: none;
  &:hover {
    background-color: darken($button-bg-color, 5%); } }

.quickdate-sm-btn {
  float: right;
  padding: 0.25em 10px;
  margin-top: 0.25em;
  &.ok {
    margin-left: 5px;
    color: white !important;
    background: #05759e !important; }
  &.cancel {
    background: #ECECEC !important;
    color: #4d4d4d !important; } }

// MAIN DO NOT CHANGE BELOW

.quickdate {
  display: inline-block;
  position: relative; }

.quickdate-button, .quickdate-action-link {
  div {
    display: inline; } }

.quickdate-popup {
  z-index: 10;
  background-color: #fff;
  border: solid 1px #000;
  text-align: center;
  width: 250px;
  display: none;
  position: absolute;
  padding: 5px;
  &.open {
    display: block; } }

.quickdate-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #333;
  font-size: 110%;
  margin-top: -6px;
  text-decoration: none;
  &:hover {
    text-decoration: underline; }
  &:hover, &:visited {
    color: #333; } }

.quickdate-calendar-header {
  display: block;
  padding: 2px 0;
  margin-bottom: 5px;
  text-align: center; }

.quickdate-month {
  display: inline-block; }

a.quickdate-prev-month {
  float: left; }

a.quickdate-next-month {
  float: right; }

.quickdate-text-inputs {
  text-align: left;
  margin-bottom: 5px; }

.quickdate-input-wrapper {
  width: 48%;
  display: inline-block; }

input.quickdate-date-input, input.quickdate-time-input {
  width: 100px;
  margin: 0;
  height: auto;
  padding: 2px 3px; }

table.quickdate-calendar {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-top: 5px;
  th, td {
    padding: 5px; }
  td {
    &:hover {
      cursor: pointer; } } }

.quickdate-popup-footer {
  text-align: right;
  display: block; }

.quickdate {
  input.ng-invalid {
    border: 1px solid $error-color; }
  input.ng-invalid:focus {
    outline-color: $error-color; } }
