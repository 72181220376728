.formitem {
    /*border: 1px solid #BBB;*/
    padding: 10px 10px;
    border-radius: 5px;
    margin: 0 0;
 }    // background-color: rgba(250,250,250,0.7)
.form-field-divider {
    margin-top: 0px;
    margin-bottom: 0px; }
/*
 * Field styles...
 */

.text-field-input {
    border-radius: 4px;
    background-color: #ececec;
    margin-left: 0 !important; }

.invalid {
    background-color: rgba(255, 148, 148, 0.27); }



.selected, .field-over:hover {
    //box-shadow: 0 0 10px rgba(73, 131, 177, 0.5)
    background-color: #f2f2f2;
    -webkit-radius: 0;
    border-radius: 0; }

// .nn-form-editor
//     background: #2c2c2c
//     h3
//         color: #eee
//         i
//             opacity: 0.3
//             &:hover
//                 cursor: pointer
//                 opacity: 0.8
//                 color: white
//             &.right
//                 margin-top: 10px
