@mixin flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

@mixin boundary-separation {
  margin-bottom: 10px; }

.seo-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; }

.mobile-display {
  h3 {
    font-size: medium !important; }

  .dialog-message {
    padding: 8px !important; }

  .dialog-assistance {
    max-height: 350px !important;
    display: unset !important;

    .seo-content {
      flex-direction: column !important;

      .count {
        width: 100% !important;
        text-align: right; } } }

  .dialog-assistance.hide {
    display: none !important; }

  .dialog-seo-input-container {
    flex-direction: column;
    height: unset !important;

    #story-seo-meta-description {
      height: 88px !important; } }

  .dialog-footer {
    padding-top: unset !important;
    justify-content: space-evenly !important; } }

@media only screen and (max-height: 720px) {
  .assistance-container {
    width: 1000px;
    max-height: 600px;

    .mobile-display {
      .dialog-assistance {
        max-height: 250px !important; } }

    .dialog-assistance {
      justify-content: unset !important; } } }

.assistance-container {
  @include flex-col;
  padding: 12px;
  width: 100%;

  .dialog-title {
    @include boundary-separation;
    text-align: left;
    color: #fff; }

  .dialog-assistance, .dialog-message {
    @include flex-col;
    background-color: #ec8a34;
    padding: 8px;
    text-align: left;
    border-radius: 4px;

    .fa-exclamation-triangle {
      color: orange; } }

  .dialog-message {
    @include boundary-separation;
    font-size: small; }

  .dialog-assistance {
    background-color: #ffff86;
    max-height: 420px;
    overflow-y: auto; }

  .dialog-assistance.assistance {
    padding: 0 20px;
    background-color: #fff; }

  .error-message {
    p {
      margin-bottom: unset; } }

  .retry {
    border-radius: 3px;
    padding: 5px;
    font-size: small; }

  .dialog-seo-input-container {
    .input {
      border: 1px solid white; }

    #story-seo-title, #story-seo-meta-description {
      height: 64px;
      resize: none;
      border-radius: 2px;
      font-size: small;
      border: 1px solid lightgrey; }

    label {
      font-weight: bold; }

    hr {
      margin: 1rem 0 1rem; } }

  .input.valid-input {
    border-color: #16A34A !important; }

  .input.invalid-input {
    border-color: #FF1A1A !important; }

  .seo-options {
    h3 {
      font-weight: bold; } }

  .seo-container {
    > :first-child {
      margin-bottom: 16px; } }

  .seo-contents {
    margin: unset;

    .selected {
      background-color: lightblue; }

    .seo-content {
      align-items: center;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 4px 8px;

      &:hover {
        background-color: #F4F4F5; }

      .content {
        width: 88%;
        max-height: 40px;
        overflow-y: auto; }

      .count {
        margin-left: auto;
        font-size: 12px;
        width: 7%; } } }

  .remark {
    font-weight: 500;
    color: white;
    font-size: small;
    margin-bottom: unset !important;

    .fa-exclamation-triangle {
      color: yellow; } }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      height: 36px;
      margin-bottom: unset;
      line-height: unset;
      letter-spacing: 2px;
      font-weight: 500;
      border-radius: 4px; }

    > :first-child {
      margin-right: 20px; } }

  .dialog-footer-action {
    text-align: center;
    font-size: 0.8rem !important;
    padding: 10px 20px; } }
