.page-tops-container {
  position: relative;
  .close-panel {
    top: 3.25em;
    right: 0.75em; }
  .page-tops-panel {
    position: absolute;
    top: 2.3em;
    right: 0;
    width: 20em;
    padding: 1em 0 1.5em 1em;
    border: 1px solid #dee5e7;
    border-width: 0 1px 1px 1px;
    cursor: default;
    .nn-page-tops {
      h3 {
        margin-bottom: .8em; }
      i.resetSelect {
        margin: 0.6em 0 0 0.4em; }
      i.resetInput {
        margin: 0.6em 0 0 0.4em; }
      i.input-chevron {
        top: 8px; }
      .select2 {
        a.select2-choice {
          border-radius: 0; }
        .select2-drop {
          color: #7E7E7E;
          padding-top: 0;
          border-color: #ccc; }
        .select2-search {
          display: none; }
        .select2-choice {
          display: none; } } } } }
