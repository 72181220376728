@import "~styles/mixins";

.nn-story-info {
  .story-info.page-elements-summary {
    .list-group-item {
      padding: 0 0 0 .2em !important; } }
  #container.module-info {
    width: 421px; }
  .list-group-item {
    i {
      transition: color .1s; }
    &.active {
      i {
        color: #555; } }
    &.inactive {
      i {
        color: #d3d3d3; } } } }
.collapsible-content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: white; }
@mixin update-breaking-news-content-layout {
    width: 100%;
    border-radius: 4px;
    overflow: auto;
    cursor: pointer; }
.update-email-collapsible-content {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 1200px;
  padding: 0px 15px;
  transition: max-height 0.45s ease-in-out 0s;
  .warning-breaking-news {
    @include update-breaking-news-content-layout;
    height: 100%;
    background-color: #ec8a34;
    margin: 10px 0;
    div {
      padding: 3px 15px; }
    h1 {
      font-size: 18px;
      color: white;
      line-height: 1; }
    .warningText {
      font-size: 14px;
      color: white;
      line-height: 1.4; }
    .warningList {
      color: white;
      line-height: 1.4; } }
  .preview-breaking-news {
    @include update-breaking-news-content-layout;
    height: 65%;
    background-color: #DDDDDD;
    div {
      padding: 3px 15px; }
    h1 {
      font-size: 18px;
      color: black;
      line-height: 1; }
    .previewContent {
      font-size: 14px;
      color: black;
      line-height: 1.4;
      margin-bottom: 0px !important; } } }
.column-layout {
  display: flex;
  flex-direction: column;
  margin-top: 8px !important;
  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0rem; } }
.center-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  text-align: center;
  line-height: 200%; }
.graphic-stats {
  margin-left: 30px;
  border: none !important; }
.detail-report-row {
  display: flex;
  flex-direction: row;
  align-items: center; }
.detail-report-container {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  align-items: center; }
.detail-report-title {
  color: #36749b;
  padding-left: 18px;
  padding-right: 40px; }
.font-size-lg {
  font-size: 1.2em !important; }
.font-size-xlg {
  font-size: 2.5em !important; }
.live_mode_container {
  display: flex;
  flex-direction: row;
  width: 100%; }
.live_mode_left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
.live_mode_right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px; }
.canvas {
  width: 200px;
  height: 100px; }
.refresh-data {
  border: 1px solid lightgrey;
  padding: 5px 8px;
  border-radius: 3px; }
.cursor-pointer-icons {
  cursor: pointer; }
.fa-refresh:hover {
   color: unset !important; }
.fa-info-circle:hover {
   color: unset !important; }
.social {
  @include chartbeat(#b2abd2); }
.internal {
  @include chartbeat(#f7da9f); }
.direct {
  @include chartbeat(#fd8d3c); }
.links {
  @include chartbeat(#6baed6); }
.search {
  @include chartbeat(#74c476); }
