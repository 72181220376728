body.bootstrap-style {
  color: #3B3E42;
  padding-top: 10px;

  nav + .container {
    margin-top: 0; }

  .navbar-fixed-top {
    background: url(https://newsnow.pitcre.ws/images/newsnow.png) no-repeat 14px 16px transparent;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: #05759e;
    width: 70px; }

  .navbar .navbar-inner {
    background: none;
    display: none; }

  .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: 100% !important; }

  #main .row .span12 {
    min-width: 280px;
    max-width: 500px;
    margin: 0 auto;
    float: none;
    p {
      font-size: 1.5em;
      line-height: 1.25em;
      margin-bottom: 0.5em;
      opacity: 0.6;
      color: #3b3e42; }
    input {
      border-radius: 1px;
      width: 100%;
      padding: 18px 10px;
      margin-bottom: 0;
      box-sizing: border-box;
      font-size: 1.25em;
      color: #6f6f6f; }
    label {
      font-size: 1.25em;
      color: #3b3e42;
      margin-top: 1em; } }

  form {
    max-width: 350px; }

  .helptext {
    clear: both;
    display: block;
    font-style: italic;
    color: lightgrey; }

  input[type="submit"], .btn {
    background: #05759e;
    color: white !important;
    border: 0;
    margin-top: 1.5em;
    width: auto !important;
    padding: 10px 40px !important; }

  .btn {
    margin-top: 1.85em !important;
    padding: 10px 30px !important;
    border-radius: 0 !important;
    background: grey !important;
    text-shadow: none !important;
    float: right !important; }

  .btn-primary {
    background: #05759e !important;
    margin-top: 1.5em !important;
    padding: 10px 40px !important;
    float: none !important; }

  .errorlist {
    text-align: right;
    color: red;
    margin: 0;
    float: right; }

  .form-horizontal .control-label {
    width: 100% !important;
    text-align: left !important; }

  .form-horizontal .controls {
    margin: 0 !important; }

  .form-horizontal .form-actions {
    padding: 0 !important;
    background: transparent !important;
    margin: 0 !important; }

  #footer {
    display: none; } }
