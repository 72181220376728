.nn-task-search {

  .no-tasks {
    color: #ccc; }

  .animate-if.ng-enter, .animate-if.ng-leave {
    -webkit-transition: all linear 0.25s;
    transition: all linear 0.25s; }

  .animate-if.ng-enter, .animate-if.ng-leave.ng-leave-active {
    opacity: 0; }

  .animate-if.ng-leave, .animate-if.ng-enter.ng-enter-active {
    opacity: 1; }

  .select2-container {
    margin: 0 0.25em 0 0; }

  .select2-choice>.select2-chosen {
    line-height: 1.4em !important; }

  .toolbar {
    padding: .75em !important; }

  .cmn-toggle-round-flat:checked + label:before {
    background-color: #8E8E8E !important; }

  ul.select2-result-single {
    margin: 0;
    li {
      color: #333;
      &.select2-highlighted {
        color: #000;
        background-color: #E0E0E0; }
      .select2-result-label {
        font-size: 0.875rem;
        line-height: 1.3em; } } } }
