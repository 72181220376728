@import "~styles/mixins";
.nn-file-list {
  width: 100%;
  .file-list {
    width: 85%;
    margin: 0 auto !important;
    text-align: center; }
  ul {
    li {
      .file-wrapper {
        max-width: 135px;
        min-height: 100px;
        width: auto;
        float: left;

        border: 1px solid #d3d3d3;
        border-bottom: 1px solid #c4c4c4;
        box-shadow: 0 1px 0 rgba(0,0,0,0.07);
        background: #fafafa;
        color: #444;
        .file-details-wrapper {
          border-top: 1px solid #e2e2e2;
          margin-top: 1px;
          padding: 0.25em;
          @include favourites;
          .favourites {
            position: static; }
          .caption, time {
            @include font-size(6); }
          .caption {
            font-weight: bold;
            clear: both;
            width: 100%;
            display: block; } } }

      &:hover {
        .file-wrapper {
          border-color: $brand-bg; } }
      &.active {
        .file-wrapper {
          border-color: $link-color; } } } } }



.favourites.image-spinne {

  background-image: url(/images/loader.gif);
  background-position: center;
  background-repeat: no-repeat; }
