@import "~styles/mixins";
.nn-task-comment-list {
  .streamline,.streamline-item:after {
    top: 7px !important; }
  .streamline:after,.streamline-item:after {
    width: 10px !important;
    height: 10px !important;
    margin-left: -7px !important; }
  .streamline .streamline-item:after {
    border-color: white !important; }
  .streamline {
    .dot {
      border-color: $brand-bg !important; }
    &:after {
      margin-left: -6px !important; } }
  .streamline-item {
    &:hover {
      cursor: default !important; } }

  border: 0;
  @include font-size(9);
  textarea {
    @include font-size(6); }
  .comment-button {
    margin: 0.4em 0 0.8em; }
  .comments-header {
    @include font-size(6);
    padding-left: 3.3em; }
  .comments-person {
    position: absolute;
    top: 20px;
    height: 32px;
    width: 32px;
    img {
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      height: 100%;
      width: 100%; } }
  .comments-label, .comments-time {
    display: inline-block; }

  .comments-annotation {
    color: #777;
    @include font-size(6);
    padding-left: 1em; }
  .comments-time {
    vertical-align: top;
    position: static;
    padding: 0;
    margin-top: 0.35em;
    opacity: 1; }
  .comments-body {
    p {
      @include font-size(6);
      line-height: 1.4em; } } }



