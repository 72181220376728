@import "~styles/mixins";
.nn-file-editor {
  position: relative;
  height: 100%;
  background: black;
  img {
    max-width: 90%;
    @include pos(absolute,50%,auto,auto,50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  object {
    padding: 2rem;
    width: 100%;
    height: 100%; } }
