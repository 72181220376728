
@import "~styles/mixins";
.page-elements-item-summary {
  .thumbnail {
    padding: 0.5em 1.5em;
    clear: both; }

  .preview {
    width: 100%;
    height: 100%; }

  .thumbnail-buttons {
    cursor: pointer;
    position: absolute;
    top: 7%;
    right: 0;
    bottom: 0;
    left: 0;
    height: 93%;
    width: 100%;
    z-index: 1;

    &:hover i {
      visibility: visible; }

    i {
      background: #05759E;
      border-radius: 50%;
      margin-left: 0.25em;
      margin-top: 40%;
      visibility: hidden;
      color: white;
      padding: 0.30em 0.35em;
      -webkit-box-shadow: -2px 0 20px 0 rgba(0, 0, 0, 0.25);

      &:hover, &.active {
        color: $brand-bg; }

      &.active {
        background: white; } } }

  .canvas-svg {
    svg {
      height: auto; } } }
