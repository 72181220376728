@import "~styles/mixins";

.nn-task-list {
  display: inline-block;
  width: 100%;

  .tasks-list {
    width: 100%;
    display: inline-block;
    margin: 0;

    .nn-explorer & {
      padding: 1em 0; }

    li {
      list-style: none;
      width: 100%;
      position: relative;
      float: left;
      border-bottom: 1px solid #eee;
      @include font-size(7);
      padding: 0.9em 1em;

      .nn-explorer & {
        border-bottom: 1px solid #f2f2f2; }

      &.view-more {
        cursor: pointer;
        opacity: .5;
        &:hover {
          opacity: 1; } }

      &.view-more, &.loading, &.no-tasks {
        padding: 2em !important; }

      .task-wrapper {
        padding-left: 3em;
        width: 60%;
        margin-top: 0.4em; }

      &.hover {
        background: #f3f3f3; }

      &.flagged {
        border-left: 7px solid #D0021B !important; }

      &.active {
        border-left: 7px solid rgba(4, 101, 136, 0.19);

        .task-wrapper {
          width: 95%; } }

      .task-detail-type {
        padding: 0.35em 1em;
        border-radius: 3px; }

      .task-detail-title {
        line-height: 1.4em;
        padding-left: 0.5em; }

      .task-header {
        cursor: pointer;
        left: 0;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;

        &:hover {
          background: lightgrey;
          opacity: 0.1; } }

      .task-header-open {
        height: 60px; }

      .task-detail-time {
        line-height: 2.5em;
        font-size: 0.78rem;
        color: #A9A9A9; }

      .task-detail-description {
        line-height: 1.3em;
        @include font-size(7);
        color: #3B3E42;
        white-space: initial;

        .fa-asterisk {
          opacity: 0.4;
          position: absolute;
          top: 3px;
          left: 2px; } }

      .task-detail {
        line-height: 1.5em;
        margin-top: 1.5em; }

      .task-detail-item {
        line-height: 1.4em;
        font-size: 0.875rem;
        display: block;
        color: #666;

        i {
          padding-right: 0.45em;
          font-style: normal; } }

      .task-detail-comments {
        textarea {
          @include font-size(8); } }
      .task-detail-buttons {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        z-index: 1;
        a {
          color: $brand-bg;
          padding: 0.25em 0 0.2em 0.3em;
          i {
            padding-right: 0.25em; }
          span {
            display: none; }
          &:hover {
            color: white;
            background: $brand-bg; } } } } }

  .total-comments {
    @include font-size(7);
    line-height: 1;
    margin-top: -3px;
    display: inline-block; }
  .nn-explorer & {
    width: 100%; } }

.tasks-list {
  .task-button {
    a {
      color: #333;
      opacity: 0.6;
      margin-bottom: 0 !important;
      .fa {
        margin-bottom: 2px; }
      &:hover {
        opacity: 0.8;
        color: $link-color;
        text-decoration: none; } } } }
