.hint {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    span {
        font-size: 12px;
        font-style: italic; } }

.formWarning {
    width: 100%;
    height: 100px;
    background-color: #ec8a34;
    border-radius: 4px;
    div {
        padding: 3px 15px; }
    h1 {
        font-size: 18px;
        color: white;
        line-height: 1; }
    p {
        font-size: 15px;
        color: white;
        line-height: 1.3; } }

.spinner-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    p {
       padding-bottom: 50px; } }
