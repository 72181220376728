.nn-profile-form {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  padding: 2em;
  opacity: 1;
  max-width: 700px;
  transition: .2s linear opacity;
  form {
    margin: 0; }
  h4 {
    text-align: center;
    margin: 0 0 1.5em 0; }
  .ng-enter & {
    opacity: 0; }
  .ng-enter.ng-enter-active & {
    opacity: 1; }
  .ng-leave & {
    opacity: 1; }
  .ng-leave.ng-leave-active & {
    opacity: 0; }
  .mugshot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    margin-bottom: 1em;
    text-align: center;
    border: 1px #ccc dashed;
    overflow: hidden;
    padding: 2em 1em;
    &.error {
      border: 1px solid red; }
    .preview {
      > img {
        border-radius: 100%; } }
    img {
      max-height: 10em; }
    input {
      margin: 0;
      cursor: pointer; }
    .remove {
      display: none;
      z-index: 1; }
    &:hover {
      .remove {
        display: block; }
      border-style: solid;
      opacity: .5; }
    .progress {
      height: .4em;
      border: none;
      padding: 0;
      margin-bottom: 0; }
    small {
      margin-top: 1em; } }
  .error.too-small {
    color: white;
    line-height: 1.3em;
    font-size: 0.9em;
    background: red; }
  button {
    transition: background-color 0s linear;
    &.save {
      order: 1; } }
  textarea[name="bio"] {
    background: white;
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem; }
  &:hover {
    > .remove {
      display: block; } }
  .remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 2em;
    height: 2em; }
  > .remove {
    display: none; }
  .social {
    margin-bottom: 2em;
    input {
      margin-bottom: 2em; }
    .more {
      cursor: pointer;
      opacity: 0.7;
      clear: both;
      span {
        margin-left: .3em; }
      &:hover {
        opacity: 1; } }
    ul {
      list-style: none;
      margin: 1em 0 0 0;
      width: 100%;
      li {
        margin: .5em 0; } }
    // &.active
    //   header
    //     border-bottom: none
    //   ul
    //     border: 1px solid #eee
 }    //     border-top: none
  footer {
    display: flex;
    button {
      flex: 1; } }
  label {
    position: relative; }
  .field-error {
    font-size: 0.9em;
    position: absolute;
    top: calc(100% + 5px);
    left: 0; }
  .org-select .clear {
    display: none; } }

.nn-profile-admin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  justify-content: center;
  .nn-profile-form {
    margin: 1em 1em 0 0; } }

.nn-profile-widget {
  margin-bottom: 1em;
  .no-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.3em;
    .mugshot {
      margin: 1.5em; } } }

.nn-profile-card {
  display: flex;
  flex-direction: row;
  background: white;
  padding: 1em;
  position: relative;
  &:hover {
    .edit {
      display: block; } }
  .mugshot {
    flex: .5;
    margin-top: .5em;
    margin-right: 1em;
    > img {
      border-radius: 100%; } }
  .info {
    flex: 2;
    h3 {
      margin-bottom: 0; }
    h5 {
      font-weight: 200; }
    p {
      font-size: .8em;
      margin-bottom: 1rem;
      line-height: 1.3; }
    ul {
      list-style: none;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 10px; } } }
  .edit {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover {
      i {
        color: #046588; } }
    i {
      color: #05759e;
      font-size: 1.3em; } }
  .facebook {
    color: #3b5998; }
  .twitter {
    color: #55acee; }
  .instagram {
    color: #125688; } }
