@import "~styles/mixins";
.nn-activity-list {
  border: 0;
  @include font-size(9);
  .list {
    @include nomargin;
    @include nopadding;
    li {
      min-height: 3em;
      &.latest, &:hover {
        cursor: pointer;
        background: #eee; } } }
  .activity-header {
    @include font-size(6);
    padding-left: 3.3em; }
  .activity-label, .activity-time {
    display: inline-block; }
  .activity-label {
    .label {
      letter-spacing: 1px; } }
  .activity-annotation {
    color: #777;
    @include font-size(6);
    padding-left: 1em; }
  .activity-time {
    vertical-align: top; }
  .activity-body {
    p {
      @include font-size(6); } } }


