$bg: white;
$dark-bg: #eee;
$panel-bg: #f9feff;
$panel-active: #ffffff;
$panel2-bg: #393939;
// alternative theme
$bg-alt: #0e334c;
$dark-bg-alt: #0a1f2e;
$panel-bg-alt: #F5FDFF;
$panel-active-alt: #fafafa;
$panel2-bg-alt: #393939;
$infopanel-bg: #F6F8F9;

$brand-bg: #00aeef;
$nav-panel-small-width: 60px;

$nav-width: 70px;
$nav-full-width: 400px;
$link-color: #05759e;
$link-panel-color: white;

$warning: #f08a24 !default;

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie);
 }  //IE8

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin text-hide {
  text-indent: -10000px; }


@mixin text-hide {
  text-indent: -10000px; }

@mixin pos($pos: absolute, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left; }


@mixin narrow($width:auto,$height:auto,$left:auto,$left:auto,$top:auto) {
  &.narrow {
    width: $width;
    height: $height;
    margin-left: $left;
    left: $left;
    top: $top; } }

@mixin stickyBottom {
  bottom: 0;
  position: fixed; }

@mixin font-size($sizeValue: 12) {
  font-size: $sizeValue + px;
  //fallback for old browsers
  font-size: 0.125 * $sizeValue + rem; }

@mixin badges {
  padding: 0.15rem 0.25rem;
  @include font-size(1);
  &.dots {
    padding: 0 3px;
    margin-right: 1em; }
  &.up {
    position: relative;
    top: -10px;
    padding: 3px 6px;
    margin-left: -10px; }
  &.xs {
    padding: 2px 5px 3px;
    @include font-size(5); } }

@mixin line-height($heightValue: 12) {
  line-height: $heightValue + px;
  //fallback for old browsers
  line-height: 0.125 * $heightValue + rem; }

@mixin scroll {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
  box-sizing: content-box; }

@mixin icon-loc {
  &:after {
    font-family: FontAwesome;
    @include pos(absolute,14px,12px,auto,auto);
    -webkit-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden; } }

@mixin icon-rotate($rotate) {
  @if $rotate == short {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  @else if $rotate == half {
    -webkit-transform: rotate(145deg);
    -ms-transform: rotate(145deg);
    transform: rotate(145deg); }
  @else if $rotate == full {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); }
  @else if $rotate == double {
    -webkit-transform: rotate(780deg);
    -ms-transform: rotate(780deg);
    transform: rotate(780deg); } }


@mixin logo {
  background: url(/images/newsnow.png) no-repeat 19px 7px transparent;
  background-size: 43%;
  @include text-hide;
  height: 70px; }

@mixin padding {
  padding: 1em; }

@mixin nomargin {
  margin: 0; }

@mixin noborder {
  border: 0; }

@mixin nopadding {
  padding: 0; }


@mixin chartbeat($color) {
  .progress .meter {
    background-color: $color; } }

// styles for chartbeat Fullscreen
@mixin chartbeat-fullscreen {
  .fullscreen-hide {
    display: none; }
  width: 95%;
  height: 95%;
  padding-top: 1em;
  h2 {
    @include font-size(30); }
  h3 {
    @include font-size(20); }
  h4 {
    @include font-size(30); }
  h6 {
    @include font-size(15); }
  .auto-loop {
    @include font-size(15);
    width: 1.3em;
    text-align: center;
    input {
      margin: 0.3em auto 0 !important; } }
  .pricing-table {
    li.price {
      .fa {
        @include font-size(20); }
      .fullscreen {
        opacity: 1; } }
    li {
      @include font-size(15); } }
  .nn-chartbeat-stats {
    .pricing-table {
      margin-top: 2em; } } }


@mixin custom-list-dark {
  @include nomargin;
  @include nopadding;

  li {
    .story-tags {
      small {
        color: lightblue; } }
    .story-summary {
      p {
        color: #bbb; } }
    &:hover {
      cursor: pointer; } } }


@mixin favourites {
  .favourites {
    top: 0.5em;
    left: 0;
    @include font-size(7);
    color: #D9DCDF;
    opacity: 1;
    &.active {
      color: #F5A623;
      margin-top: -2px;
      @include font-size(7); }
    &:hover {
      color: #F5A623; } } }

@mixin flagged {
  .flagged {
    position: absolute;
    bottom: 0.7em;
    right: 0.5em;
    @include font-size(6);
    color: #E5E7E9;
    opacity: 1;
    &.active {
      color: red;
      margin-bottom: -2px;
      @include font-size(7); } } }
