@import "mixins";

.nn-nav {

  header {
    background: $brand-bg;
    height: 45px;
    width: $nav-width;
    @include pos(fixed, 0, auto, auto, 0);
    z-index: 109;
    h1 {
      @include logo;
      margin: 0;
      width: $nav-width; } }


  .primary-nav {
    z-index: 110;
    @include scroll;
    background: $link-color;
    width: $nav-width;
    list-style: none;
    @include pos(fixed,45px,auto,0,0);
    height: 2000px;

    li {
      nav {
        background: $panel-active; }
      a {
        color: white;
        padding: 11px 0 12px;
        text-align: center;
        div {
          background: $panel-active;
          margin-left: 50px; }
        .fa {
          @include font-size(10);
          vertical-align: bottom;
          line-height: 1.8rem; }
        &:hover {
          color: white;
          opacity: 0.6;
          div {
            background: $panel-active; } }
        @include icon-loc;
        .nav-label {
          display: block;
          vertical-align: middle;
          font-weight: 400;
          @include font-size(6);
          text-transform: capitalize;
          line-height: 1rem; }
        .label {
          @include badges;
          @include pos(absolute,30px,auto,auto,30px); }
        &.active {
          color: #666;
          background: white;

          &.first {
            &:after {
              right: -1000px; } } } }
      &.toggle-panel {
        a {
          background-color: $panel-bg;
          &:after {
            font-family: FontAwesome;
            content: "\f177";
            top: 5px;
            right: 10px;
            @include font-size(8); } } }
      &.last {
        @include pos(fixed,auto,auto,0,0);
        width: 70px;
        .profile {
          @include pos(absolute,auto,auto,1.35em,-2em);
          background: none; }
        a.section-link {
          padding: 2.65em 0 0 0; } } } } }
