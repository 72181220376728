@import "mixins";

html, body {
  height: 100%; }

.top-bar {
  box-shadow: 2px 2px 6px #EEE;
  li:not(.has-form) {
    a:not(.button) {
      padding: 14px 15px; } }
  .badges {
    @include badges; }
  .user-details {
    .dropdown {
      box-shadow: 0px 2px 5px #ccc; }
    .profile {
      right: 1em;
      top: 6px; }
    &.has-dropdown {
      label {
        background: $panel-bg-alt; }
      a:not(.button) {
        padding: 0 0 0 2em;
        margin: 0 2em 0 0;
        &:hover {
          background: $panel-active-alt; } }
      > a:after {
        left: 7px;
        border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent; } } } }

.main {
  /* overflow: hidden */
  background: $bg;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  vertical-align: baseline;
  color: #595959;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  @include font-size(8); }

.nn-nav {
  width: $nav-width; }

/* Editor Panel */
.nn-editor {
  z-index: 1;
  margin-right: 0;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background: transparent;
  padding-left: $nav-width; }


/* Explorer Panel */
.nn-explorer {
  background: white;
  width: 250px;
  border: 1px lightgrey solid;
  border-width: 0 1px 0 0;
  @include pos(fixed,0,auto,$nav-width,-250px);
  height: 100%;
  padding-bottom: 8em;
  overflow: visible;
  padding: 0;
  box-sizing: padding-box;
  margin: 0em 0 10em; }


/* Explorer Active Panel */
.exploreractive {
  .nn-nav {
    .nn-explorer {
      left: 70px;
      z-index: 104; }
    -webkit-transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s;
    -ms-transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s;
    transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s;
    .explorer-top-bar, .explorer-bottom-bar, .search-bar {
      -webkit-transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s;
      -ms-transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s;
      transition: all 0.2s cubic-bezier(.7, 0, .175, 1) 0s; }
    .explorer-top-bar, .explorer-bottom-bar {
      left: 0; }
    .search-bar {
      left: 70px; }
    .nav-label-sticky {
      left: 7.2em;
      top: 0.18em; } }

  &.collapsed {
    .explorer-bottom-bar {
      top: 0;
      z-index: 1000;
      opacity: 0.9; }
    .fa-exchange {
      background: $brand-bg;
      color: white; }
    .nn-editor {
      padding-left: 150px; }
    .nn-explorer {
      left: -100px;
      .search-bar {
        left: -100px; }
      .explorer-top-bar, .explorer-bottom-bar {
        left: 0; }
      .nav-label-sticky {
        left: -5em; } } }

  /* Explorer Wide Panel */
  &.explorerwide {
    .nn-editor {
      padding-left: 150px; }
    .nn-explorer {
      width: $nav-full-width;
      @media only screen and (max-width: 400px) {
         width: 75%; }
      .explorer-top-bar, .explorer-bottom-bar {
        left: 0; } }
    &.collapsed {
      .nn-explorer, .search-bar {
        left: -250px; }
      .searchbox-advance {
        left: 0; } } } }

/* Primary nav items */
.secondary-nav {
  li.submenu-item {
    a {
      width: 100%;
      display: block;
      padding: 0.75em;
      color: #3B3E42;
      &:hover {
        background: $panel-active-alt; }
      .nav-label {
        text-indent: 5px;
        display: inline-block; } } } }

